import axios from 'axios'

import store from '@/store'
// import { getToken } from '@/utils/auth'
// import router from '@/router/index.js'

// 创建一个axios实例
const service = axios.create({
    baseURL: '' //接口地址
})

// 请求拦截器
service.interceptors.request.use(
    config => {
       
        config.headers.Authorization = 'Bearer ' + store.getters.token
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data

        // 与后端约定的错误码
        if (res.code !== 200) {
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default service