<template>
  <div class="me">
    <!-- PC端 -->
    <!-- 火眼防伪 -->
    <div class="me_center" v-show="fullWidth">
        <div class="search-box">
            <div class="search-content">
                <div class="search">
                    <div class="logo-box">
                        <img class="logo" src="../assets/img/logo.png" alt=""/>
                        <p class="title">火眼防伪商品信息验证</p>
                        <p class="subtitle">sharpeyes  anti-counterfeiting commodity information verification</p>
                    </div>
                    
                    <div class="handle-box" v-if="search_type==0">
                        <div class="explain">
                            <span>① 刮开涂层</span>
                            <span>② 输入防伪码</span>
                            <span>③ 点击「防伪查询」按钮</span>
                            <span>④ 显示查询结果</span>
                        </div>
                        <div class="active">
                            <input type="text" placeholder="请输入防伪码" v-model="token"/>
                            <button class="button" @click="search">防伪查询</button>
                        </div>
                    </div>
                    <div class="content-box" v-else>
                        <div class="content">
                            <img src="../assets/img/e.png" alt="" v-if="search_type == 3"/>
                            <img src="../assets/img/r.png" alt="" v-else/>
                            <p>{{ content }}</p>
                        </div>
                        <div class="btn-box">
                            <button class="button" @click="showrecord" v-if="search_type == 2">查询记录</button>
                            <button class="button" @click="research">再次查询</button>
                        </div>
                    </div>
                    <div class="tips">
                        <p class="title">验证说明</p>
                        <p>【正品提示】：您所查询的产品为XXX（品牌名）正品，感谢您的查询。</p>
                        <p>【多次查询】：您所查询的产品为XXX（品牌名）正品，首次查询时间为xxxx年xx月xx日，本次查询为第x次查询。</p>
                        <p>【错误查询】：未查询到产品信息，请确认您输入的防伪码是否准确。</p>
                        <p>免责声明：本系统仅用于验证产品所属厂商或服务商，商品售后请联系您的销售商。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="record" v-show="show_record">
        <div class="model">
            <div class="header">
                <div class="title">查询记录</div>
                <img class="close" @click="closerecord" src="../assets/img/close.png" />
            </div>
            <div class="content">
                <div class="content-list">
                    <p v-for="(item, index) in record_list" class="content-item" v-bind:key="index">
                        <span>第{{ index+1 }}次</span>
                        <span>{{ item.created_at }}</span>
                        <span>{{ item.city }}</span>
                    </p>
                </div>
               
            </div>
        </div>
    </div>

    <!-- 移动端样式 -->
    <div class="move_center" v-show="!fullWidth">
      2
    </div>
    <!--<Footer :full='fullWidth'/>-->
  </div>

</template>

<script>
// @ is an alias to /src

import request from '@/utils/request';
export default {
  name: "Home",
  components: {

    },

  data(){
    return{
      // 轮播图
      imgs:[
        {id:1,url:require('../assets/innovative/me.png')},
        {id:1,url:require('../assets/innovative/move_me.png')},
      ],
      fullWidth:false,
      token:'',
      search_type:0,//0未查询   1查询成功（5次内）  2查询成功（5次外）  3查询失败
      content:'',
      record_list:[],
      show_record:false,
    }
  },
  created(){

  },
  methods:{
      page_width() {//获取屏幕宽度
      let screenWidth = window.screen.width;
      console.log(screenWidth);
      if (screenWidth < 800) {
        this.fullWidth = false;
        console.log(123);
        window.location.href = 'https://mp.sharp-eyes.cn/#/pages/only-token/only-token';
      } else {
        this.fullWidth = true;
      }

    },
    setRem() {
      var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
      var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
      var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
    },
    search(){
        var _this = this;
        request({
            url: 'https://cloud.sharp-eyes.cn/mp/search_token_info?token='+_this.token,
            method: 'get'
        }).then(res=>{
            _this.getSearchInfo(res.data);
        },error=>{
            _this.search_type = 3;
            _this.content = error.message;
        })
    },
    getSearchInfo(uuid){
        var _this = this;
        request({
            url: 'https://cloud.sharp-eyes.cn/mp/search_info?uuid='+uuid,
            method: 'get'
        }).then(res=>{
            if(res.data.record.length<5){
                _this.search_type = 1;
                _this.content = res.data.content;
            }else{
                _this.search_type = 2;
                _this.content = res.data.content+'本次查询为第'+(res.data.record.length+1)+'次查询。';
                _this.record_list = res.data.record;
            }
            
        },error=>{
            _this.search_type = 3;
            _this.content = error.message;
        })
    },
    research(){
        var _this = this;
        _this.token = '';
        _this.search_type = 0;
        _this.content = '';
        _this.record_list = [];
        _this.show_record = 0;
    },
    showrecord(){
        var _this = this;
        _this.show_record = true;
    },
    closerecord(){
        var _this = this;
        _this.show_record = false;
    }
  },
  mounted(){
    window.onresize = () => {//监听屏幕变化
      this.page_width();
      this.setRem()
    };
    this.page_width();
    this.setRem()
  },

};
</script>
<style scoped>
.me{

}

/* PC端样式 */
/* 头 */
.me_center{
    margin-top: 75px;
    display: flex;
    flex-direction:column;
    height: calc(100vh - 75px);
    align-items: center;
    /*justify-content: center;*/
}
.search-box{
    width: 1200px;
}
.search-content{
    padding-top: 67.37%;
    width: 100%;
    background-image:url('../assets/img/search-bg.png');
    background-position: center;
    background-size: cover;
    position: relative;
}
.search{
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 5.5%;
    display: flex;
    flex-direction:column;
    align-items: center;
    /*justify-content: center;*/
    box-shadow: 0px 8px 64px rgba(0,0,0,0.4);
}
.handle-box{
    width: 60%;
    display: flex;
    flex-direction:column;
    align-items: center;
}
.explain{
    font-size: 16px;
    width: 100%;
    display: flex;
    color: #990000;
}
.active{
    margin-top: 48px;
    width: 100%;
    display: flex;
}
.active>input{
    width: 66%;
    margin-right: 18px;
    height: 48px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.4);
    font-size: 18px;
    padding:0 12px;
}
.active>button{
    width: 33%;
}
button{
    height: 100%;
    border: 0;
    font-size: 18px;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.4);
    color: #FFF;
    background: #1B1E1E;
}
button:hover{
    background: #2B2E2E;
}

button:active{
    background: #111111;
}

.explain>span{
    margin: 0 auto;
}
.content-box{
    width: 60%;
}

.content-box>.content{
    display: flex;
    font-size: 22px;
    align-items: center;
    /*justify-content: center;*/
    font-weight: bold;
    letter-spacing:4px;
    flex-wrap:wrap;
}

.content-box>.content>p{
    max-width: 80%;
    word-wrap: break-word;
    word-break: normal;
    width: 100%;
}

.content-box>.content>img{
    width: 108px;
    height: auto;
    margin-right: 28px;
}

.content-box>.btn-box{
    padding: 0 76px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:5%;
}

.content-box>.btn-box>button{
    width: 30%;
    margin:0 auto;
}

.tips{
    font-size: 12px;
    position: absolute;
    bottom: 8%;
    width: 60%;
    line-height: 28px;
    letter-spacing:1px;
    color: #555555;
}

.tips>.title{
    font-size: 16px;
    text-align: center;
    /*font-weight: bold;*/
}

.record{
    padding-top: 75px;
    position: fixed;
    top: 0;
    background: rgba(0,0,0,0.4);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.model{
    height: 20vw;
    width: 40vw;
    background: #FFF;
}

.model>.header{
    height: 48px;
    letter-spacing:2px;
    background: #000;
    font-size: 16px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: bold;
}

.model>.header>.close{
    height: 24px;
    width: 24px;
    position: absolute;
    right: 12px;
    cursor: pointer;
}

.model>.content{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 48px);
    width: 100%;
}

.model>.content>.content-list{
    overflow-y:scroll;
    overflow-x:hidden;
    padding: 20px;
    height: 100%;
    width: 100%;
}

.model>.content>.content-list>.content-item{
    font-size: 12px;
    color:#333333;
    text-align: center;
    margin-bottom: 12px;
}

.model>.content>.content-list>.content-item>span{
    margin:0 24px;
}

.logo-box{
    padding: 32px;
    display: flex;
    flex-direction:column;
    align-items: center;
}

.logo-box>.logo{
    margin-bottom: 32px;
}

.logo-box>.title{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 4px;
}

.logo-box>.subtitle{
    font-size: 8px;
    color: #999999;
}

/* 移动端样式 */
/* 头 */
.move_center{margin-top: 20px;display: flex;flex-direction:column;}


</style>
